// Login.js
import React from 'react';
import LoginForm from './components/LoginForm';
import { Container, Row, Col } from 'react-bootstrap';

const Login = ({ onLogin }) => {
  return (
    <div className="d-flex flex-column min-vh-100">
      <Container className="d-flex flex-grow-1 justify-content-center align-items-center">
        <LoginForm onLogin={onLogin} />
      </Container>
      <footer className="bg-transparent py-3">
        <Container className="text-center">
          <p><small className="mb-0">© 2024 Foreign Investment Company S/A</small><br/>
          <small><a href="https://ficoglobal.com/">www.ficoglobal.com</a></small></p>
        </Container>
        <Container>
          <Row className="justify-content-center mt-2 text-center">
            <Col>
              <p>
              <small>FICO S/A (Headquarters): Registro Publico Nº 1323308 - Foreign Investment Company S/A - Republica del Panama</small><br/>
              <small>Carbon Bank FICO (Brazilian branch): CNPJ Nº 29.289.880/0001-40 - Banco FICO de Carbono Florestal LTDA - República Federativa do Brasil</small>
              </p>
            </Col>
          </Row>
        </Container>
      </footer>
    </div>
  );
};

export default Login;
