import React, { useState } from 'react';
import axios from '../axiosConfig';
import { Container, Row, Col, Card, Image, Form, Button } from 'react-bootstrap';
import logo from './logo.png';
import config from '../config';

const LoginForm = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${config.apiUrl}/login`, {
        username,
        password,
      });
      onLogin(response.data);
    } catch (err) {
      setError('Invalid CCS Code or Key.');
    }
  };

  return (
    <Container className="text-center d-flex align-items-center justify-content-center">
      <Row>
        <Col md="auto">
          <Card className="ccs-card p-3">
            <Card.Body>
                <Image height={66} src={logo} className="text-center" />
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="username" className="my-2">
                  <Form.Control
                    className="text-uppercase"
                    placeholder="CCS Code"
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="password" className="my-2">
                  <Form.Control
                    placeholder="CCS Key or Certificate ID"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </Form.Group>
                {error && <p className="text-danger">{error}</p>}
                <Button size="lg" variant="outline-warning"  className="w-100 mt-2" type="submit" >
                  Validate
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default LoginForm;
