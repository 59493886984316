import React, { useEffect, useState } from 'react';
import Login from './Login';
import ClientView from './ClientView';
import AdminView from './AdminView';
import CertificateGuestView from './CertificateGuestView';
import axios from './axiosConfig';
import config from './config';

const getInitialApiToken = () => {
  const expirationTimestamp = sessionStorage.getItem('api_token_expiration');
  if (expirationTimestamp && Date.now() < Number(expirationTimestamp)) {
    return sessionStorage.getItem('api_token');
  }
  return null;
};

const App = () => {
  const [apiToken, setApiToken] = useState(getInitialApiToken());
  const [userRole, setUserRole] = useState(null);
  const [readonlyToken, setReadonlyToken] = useState(null);

  const fetchUserRole = async () => {
    if (!apiToken) return;

    const requestConfig = {
      headers: { Authorization: `Bearer ${apiToken}` },
    };

    try {
      const response = await axios.get(`${config.apiUrl}/user/role`, {}, requestConfig);
      setUserRole(response.data.role);
    } catch (error) {
      console.error('Error fetching user role:', error);
    }
  };
  const handleLogout = () => {
    setApiToken(null);
    setUserRole(null);
    setReadonlyToken(null);
    sessionStorage.removeItem('api_token');
    sessionStorage.removeItem('api_token_expiration');
  };

  const handleLogin = (data) => {
    if(data.api_token)
    {
      setApiToken(data.api_token);
      const expirationTimestamp = Date.now() + 60 * 60 * 1000; // 1 hour from now
      sessionStorage.setItem('api_token', data.api_token); // Save the api_token in sessionStorage
      sessionStorage.setItem('api_token_expiration', expirationTimestamp);
    } else if(data.readonly_token) {
      setReadonlyToken(data.readonly_token);
    }
  };

  useEffect(() => {
    fetchUserRole();
  }, [apiToken]);

  return (
    <div>
      {readonlyToken || (apiToken && userRole) ? (
        userRole === 'admin' ? (
          <AdminView apiToken={apiToken} onLogout={handleLogout} />
        ) : (
          readonlyToken ? (
            <CertificateGuestView readonlyToken={readonlyToken} onLogout={handleLogout}/>
          ) : (
            <ClientView onLogout={handleLogout} />
          )
        )
      ) : (
        <Login onLogin={handleLogin} />
      )}
    </div>
  );
};

export default App;
