import React, { useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import CCSCodeForm from './components/CCSCodeForm';
import CertificateForm from './components/CertificateForm';
import CCSCodeList from './components/CCSCodeList';
import axios from './axiosConfig';
import config from './config';

const AdminView = ({ apiToken, onLogout }) => {
  const [selectedCertificate, setSelectedCertificate] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [refreshKey, setRefreshKey] = useState(0);
  const handleEditCertificate = async (certificateId) => {
    const requestConfig = {
      headers: { Authorization: `Bearer ${apiToken}` },
    };
  
    const response = await axios.get(
      `${config.apiUrl}/certificate/data/${certificateId}`,
      requestConfig
    );
  
    setSelectedCertificate(response.data);
  };
  const handleEditUser = async (userId) => {
    const requestConfig = {
      headers: { Authorization: `Bearer ${apiToken}` },
    };
  
    const response = await axios.get(
      `${config.apiUrl}/user/data/${userId}`,
      requestConfig
    );
  
    setSelectedUser(response.data);
  };
  const handleCertificateEditFinished = () => {
    setSelectedCertificate(null);
  };
  const handleUserEditFinished = () => {
    setSelectedUser(null);
  };
  return (
    <Container>
      <Row>
        <Col md={12}>
          <Button size="lg" variant="outline-warning"  className="my-2 w-100 mt-2" onClick={onLogout}>Exit</Button>
          <Row>
           <Col lg={6} sm={12}>
            <CCSCodeForm onSuccess={() => setRefreshKey(refreshKey + 1)} selectedUser={selectedUser} onEditFinished={handleUserEditFinished} />
           </Col> 
           <Col lg={6} sm={12}>
            <CertificateForm onSuccess={() => setRefreshKey(refreshKey + 1)}  selectedCertificate={selectedCertificate} onEditFinished={handleCertificateEditFinished} />
           </Col>
          </Row>
        </Col>
        <Col>
          <CCSCodeList refreshKey={refreshKey} onEditCertificate={handleEditCertificate} onEditUser={handleEditUser} apiToken={apiToken} />
        </Col>
      </Row>
    </Container>  
  );
};
export default AdminView;
