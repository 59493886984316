import axios from 'axios';

const instance = axios.create();

// Add a request interceptor
instance.interceptors.request.use(
  function (config) {
    const api_token = sessionStorage.getItem('api_token');
    if (api_token) {
      config.headers.Authorization = `Bearer ${api_token}`;
    } else {
      delete config.headers.Authorization;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default instance;
