import React, { useEffect, useState } from 'react';
import { Col, Button, Accordion, Card, Row } from 'react-bootstrap';
import axios from '../axiosConfig';
import config from '../config';
import CertificateFiles from './CertificateFiles';

const CCSCodeList = ({ refreshKey, onEditCertificate, onEditUser, apiToken }) => {
    const [ccsCodes, setccsCodes] = useState([]);

    useEffect(() => {
      const fetchData = async () => {
        const requestConfig = {
          headers: { Authorization: `Bearer ${apiToken}` },
        };
  
        const response = await axios.get(`${config.apiUrl}/user/all`, requestConfig);
        setccsCodes(response.data);
      };
  
      fetchData();
    }, [apiToken, refreshKey]);

  return (
        <Card className='ccs-card my-2 p-3'>
            <Card.Body>
                <h2>CCS Code List</h2>
                <Row>
                {ccsCodes && ccsCodes.map((ccsCode, index) => (
                <Col lg={6} sm={12} className="my-2" key={index}>
                  <Card className='ccs-card h-100'>
                      <Card.Body>
                      {
                        Object.entries({ ...ccsCode.pretty, ...ccsCode.c_c_s_code.pretty }).map(([key, value]) => (
                          <Row key={key}>
                            <Col md={3}>
                              <p><small>{key}</small></p>
                            </Col>
                            <Col>
                              <p>{value}</p>
                            </Col>
                          </Row>
                        ))
                      }
                        <Button className="my-2 w-100" variant="outline-warning" onClick={() => onEditUser(ccsCode.c_c_s_code.user_id)}>
                          Edit
                        </Button>
                          <Accordion className='ccs-accordion'>
                          <Card className='ccs-card'>
                              <Accordion.Button className='ccs-accordion-button' eventkey={index.toString()}>
                                  Certificates
                              </Accordion.Button>
                              <Accordion.Body className='ccs-accordion-body' eventkey={index.toString()}>
                                <Row>
                                  {ccsCode.c_c_s_code.certificates.length > 0 ? (
                                    ccsCode.c_c_s_code.certificates.map((certificate) => (
                                      <Card key={certificate.id} className="ccs-card my-2">
                                      <Card.Body>
                                        <Row>
                                      {Object.entries(certificate.pretty).map(([key, value]) => {
                                        if (!value) {
                                          return null;
                                        }
                                        return (
                                          <Col sm={6} key={`${certificate.id}-${key}`}>
                                            <Row>
                                              <Col>
                                                <p><small>{key}</small></p>
                                              </Col>
                                              <Col>
                                                <p>{value}</p>
                                              </Col>
                                            </Row>
                                          </Col>
                                        );
                                        })}
                                        </Row>
                                        <CertificateFiles ccsCode={certificate.ccs_code} certificateId={certificate.id} isAdmin={true} />
                                        <Button variant="outline-warning" className='my-2' onClick={() => onEditCertificate(certificate.id)}>
                                          Edit
                                        </Button>
                                      </Card.Body>
                                      </Card>
                                    ))
                                  ) : (
                                    <p>No certificates found</p>
                                  )}
                                </Row>
                              </Accordion.Body>

                          </Card>
                          </Accordion>
                      </Card.Body>
                  </Card>
                </Col>
          ))}
            </Row>
          </Card.Body>
        </Card>
  );
};

export default CCSCodeList;