import React from 'react';
import { Form } from 'react-bootstrap';
import { getNameList } from 'country-list';

const CountrySelect = ({ name, value, onChange }) => {
  const countryNames = getNameList();

  return (
      <Form.Select name={name} value={value} onChange={onChange}>
        <option>Country</option>
        {Object.entries(countryNames).map(([name, code]) => (
          <option key={code} value={code}>
            {name}
          </option>
        ))}
      </Form.Select>
  );
};

export default CountrySelect;
